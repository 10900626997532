/* Import the Roboto font */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0;

    font-family:  "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
   font-family:  "Roboto", "Helvetica Neue", sans-serif;
}
.appHideScroll {
  overflow: hidden !important;
}

.appScroll {
  overflow: auto !important; /* Using 'auto' for better default behavior */
}

.appScrollY {
  overflow-y: scroll;
}

.appScrollX {
  overflow-x: scroll;
}

.appScroll::-webkit-scrollbar {
  display: none;
  width: 0.375rem;
  height: 0.25rem;
}

.appScroll:hover::-webkit-scrollbar {
  display: inline;
}

.appScroll::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 1.5rem;
}

.appScroll::-webkit-scrollbar:hover {
  width: 0.5rem;
  height: 0.5rem;
}

.appScrollHover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
/* Width Classes */

.appWidth100 {
  width: 100%;
}

.appWidth50 {
  width: 50%;
}

.appWidth75 {
  width: 75%;
}

.appWidthInherit {
  width: inherit;
}

.appWidthCustom {
  width: var(--width);
}

/* Height Classes */

.appHeight100 {
  height: 100%;
}
.appFixedHeight100 {
  height: calc(100vh - 5rem);
}

.appHeightInherit {


  height: inherit;
}

.appHeightCustom {
  height: var(--height);
}

/* Text Transform Classes */

.appTextBold {
 font-family:  "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold; /* Removed !important for better specificity */
}

.appTextWeight400 {
 font-family:  "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.appTextWeight500 {
 font-family:  "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.appTextWeight600 {
 font-family:  "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 600;
}

.appTextWeight700 {
 font-family:  "Roboto", "Helvetica Neue", sans-serif;
  font-weight: 700;
}

.appTextCapitalize {
  text-transform: capitalize;
}

.appTextLowerCase {
  text-transform: lowercase;
}

.appTextUpperCase {
  text-transform: uppercase;
}
li {
   color : #444E56;
}
/* Text Align Classes */
.MuiGrid-root.MuiGrid-item.userMessage {
  margin-right: 0.5rem;
  text-align: start;
  padding: 10px !important;
  max-width: 75%;
  /* background: #f5f7fa; */
  border-radius: 8px;
  flex: none;
}
 
.MuiGrid-root.MuiGrid-item.botMessage {
  margin-left: 0.5rem;
  text-align: start;
  padding: 10px !important;
  max-width: 75%;
  /* background: #e1ebff; */
  border-radius: 8px;
  flex: none;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-item.sendMessageContainer {
  padding: 0px 24px;
  margin-top: 1rem;
  /* margin-left: 1rem; */
  border-radius: 40px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-item.sendMessageContainer{
  background: #f4f4f4;
}

.MuiButton-root.attachmentButton {
  border-radius: 50%;
  margin-left: 8px;
  padding: 0.5rem;
  height: 3.5rem;
  width: 3.5rem;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

p#USERCHATINPUT-helper-text {
  padding-bottom: 12px;
}
.MuiTypography-h4.chatHeaderText{
  text-align: start;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
color: #2683c8!important;
padding-bottom:1.7em!important;
}
.css-5ammod-MuiTypography-root {
  margin: 0;
  font-family: "Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.235;
}
h3.MuiTypography-h3,.css-5ammod-MuiTypography-root {
  margin:0;
  font-family:"Roboto","Helvetica Neue",sans-serif;
  font-weight:400;
  font-size:3rem;
  line-height:1.235;
  color: #2683c8!important;
}
h3.css-1jbvhfz-MuiTypography-root {
color: #2288cf;
  font-weight: 400;
  font-size: 3rem;
  color: #2683c8!important;
}
.css-14lu8z9-MuiTypography-roo{
  font-weight: 400;
  }
/* Base styles for .chatScrollContainer */
.chatScrollContainer {
  overflow-y: auto;
}

/* for bot file check reply */

@keyframes backgroundTransitionGreen {
  0% {
    background-color: #2288cf; /* Initial blue color */
  }
  100% {
    background-color: #528304; /* Final green color */
  }
}

@keyframes backgroundTransitionRed {
  0% {
 background-color: #2288cf; /* Initial blue color */
  }
  100% {
    background-color: red; /* Final red color */
  }
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #75787b;
  animation: loadingSpinner 1s linear infinite;
}

.file-status-box {
  border: 1px solid #75787b;
  border-radius: 1rem;
  padding: 4px 12px;
  margin-top: 4px;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 8px;
}

.file-status-box.success {
  animation: backgroundTransitionGreen 1s forwards;
  background-color: green;
  color: white;
}

.file-status-box.fail {
  animation: backgroundTransitionRed 1s forwards;
  background-color: red;
  color: white;
}

 .css-hyum1k-MuiToolbar-root {
    min-height: 64px;
   
  }
  .css-imr15z-MuiPaper-root-MuiAppBar-root {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)!important;
      padding: 1rem,
    
}
.css-axov4s-MuiPaper-root-MuiAppBar-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)!important;
   }
   
   .MuiBox-root p.css-1gdbbsd-MuiTypography-root{
       margin: 0;
       font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
       font-weight: 300;
       font-size: 1.475rem!important;
       line-height: 1.63;
   color: #2683c8!important;
   }
   .MuiGrid-root.MuiGrid-container.MuiGrid-item.sendMessageContainer {
       padding: 0px 1.5em;
 
      margin-top: 0;
       border-radius: 40px;
       border: 1px solid #d5d4d4;
       background-color:#ffffff;
       
}

.css-1ftgqq7-MuiStack-root {padding:0!important}
.css-on34gn {


  background-color: #e2e2e2;
  padding: 1em!important;
  }
MuiButton-root.attachmentButton {
    border-radius: 50%;
    margin-left: 8px;
    padding: 0.5rem;
    height: 3.5rem;
    width: 3.5rem;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color:#ffffff;
}

.css-vz6cs7-MuiButtonBase-root-MuiButton-root{
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 1)!important;
   color: #568b00!important;
}
.css-vz6cs7-MuiButtonBase-root-MuiButton-root:hover,.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  /*background-color: rgba(68, 78, 86, 0.04)!important;*/
   color: #2683c8!important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  cursor: pointer;
  color: inherit;
  text-align: center;
  font-size: 1.9rem;
  color: #568b00!important;
}

@media (min-width: 300px) {
  .css-bd8u49-MuiButtonBase-root-MuiIconButton-root {
    display: none!important;
  }
}
@media (max-width: 600px) {
 h3.MuiTypography-h3,.css-5ammod-MuiTypography-root {
 
   font-size:2rem!important;
  
}
h3.MuiTypography-h3,.css-5ammod-MuiTypography-root {

  
  font-size:2rem;

  color: #2683c8!important;
}
.MuiBox-root p.css-1gdbbsd-MuiTypography-root {

  font-weight: 300;
  font-size: 1.175rem !important;
 
  color: #2683c8 !important;
}
}